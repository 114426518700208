<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="packageForm" :rules="rules" label-width="96px">
      <template>
        <topOperatingButton :isSubmitAddBtn="false" :isAuditBillBtn="false" @submitForm="submitForm(false)"
          @getQuit="getQuit" id="topOperatingButton">
        </topOperatingButton>
      </template>
      <!-- 顶部按钮 -->
      <!-- <div class="btnTOP">
        <el-button type="primary" size="mini" @click="submitForm"
          >保存
        </el-button>
        <el-button @click="getQuit" size="mini">退出</el-button>
      </div> -->
      <cardTitleCom cardTitle="优惠券券包">
        <template slot="cardContent">
          <div class="tableContent x-start">
            <el-image :src="require('@/assets/images/preferential.png')" class="marR20" style="width: 375px"></el-image>

            <div style="width: calc(100% - 395px)">
              <!-- 第一行  券包名称-->

              <el-form-item label="券包名称" class="label" prop="packageName">
                <el-input class="inputWidthM" size="mini" v-model="packageForm.packageName"></el-input>
              </el-form-item>
              <!-- 第二行  券包内容-->
              <el-form-item label="券包内容" class="label" />
              <!-- 第三行 赠送优惠券 -->
              <div class="couponTable marB20">
                <el-table ref="couponTable" :data="packageForm.marketingCouponPackageDetails" border max-height="400"
                  height="400">
                  <el-table-column label="序号" type="index" width="50" align="center" />
                  <el-table-column align="center" label="操作" width="120">
                    <template v-slot="scope">
                      <i @click="tableRow(packageForm.marketingCouponPackageDetails, 'push', scope.$index)"
                        class="el-icon-circle-plus operatePush"></i>
                      <i @click="tableRow(packageForm.marketingCouponPackageDetails, 'del', scope.$index)"
                        class="el-icon-remove operateDel"></i>
                    </template>
                  </el-table-column>
                  <el-table-column label="优惠券方案编号" width="220" align="center" prop="couponCaseNo" key="couponCaseNo">
                    <template slot="header">
                      <i style="color: #ff4949">*</i>
                      优惠券方案编号
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="
                          'marketingCouponPackageDetails.' +
                          scope.$index +
                          '.couponCaseNo'
                        " :rules="
                          rules[`marketingCouponPackageDetails.couponCaseNo`]
                        ">
                        <el-input @keyup.enter.native="
                            couponKeyupEnter($event, scope.$index)
                          " v-model="scope.row.couponCaseNo" size="mini">
                          <i slot="suffix" class="el-icon-more more" @click="openCoupon = true"></i>
                        </el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="优惠券名称" align="center" prop="couponCaseName" show-overflow-tooltip
                    key="couponCaseName" width="140" />
                  <el-table-column label="优惠券类型" align="center" prop="couponCaseTypeName" key="couponCaseTypeName"
                    width="100" />
                  <el-table-column label="优惠券形式" align="center" prop="couponModeName" key="couponModeName"
                    width="100" />

                  <el-table-column label="优惠券内容" align="center" prop="couponDesc" :show-overflow-tooltip="true"
                    key="couponDesc" min-width="100" />

                  <el-table-column label="有效期时间" align="center" prop="expirationDate" key="expirationDate"
                    :show-overflow-tooltip="true" width="300" />

                  <el-table-column label="赠券数" align="center" prop="couponQty" width="145">
                    <template slot="header">
                      <i style="color: #ff4949">*</i>
                      赠券数
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="
                          'marketingCouponPackageDetails.' +
                          scope.$index +
                          '.couponQty'
                        " :rules="
                          rules[`marketingCouponPackageDetails.couponQty`]
                        ">
                        <el-input size="mini" v-model="scope.row.couponQty" label="赠券数"
                          @input="handleInput(scope.$index)" />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="领取后多少天生效" align="center" prop="couponQty" width="145">
                    <template slot="header">
                      <i style="color: #ff4949">*</i>
                      领取后多少天生效
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="
                          'marketingCouponPackageDetails.' +
                          scope.$index +
                          '.drawDay'
                        " :rules="rules[`marketingCouponPackageDetails.drawDay`]">
                        <el-input v-model="scope.row.drawDay" size="mini"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="有效期天数" align="center" prop="couponQty" width="145">
                    <template slot="header">
                      <i style="color: #ff4949">*</i>
                      有效期天数
                    </template>
                    <template slot-scope="scope">
                      <el-form-item :prop="
                          'marketingCouponPackageDetails.' +
                          scope.$index +
                          '.validDay'
                        " :rules="rules[`marketingCouponPackageDetails.validDay`]">
                        <el-input v-model="scope.row.validDay" size="mini"></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- 第四行 券包售价 -->
              <el-form-item label="券包售价" prop="packagePrice">
                <el-input v-model="packageForm.packagePrice" size="mini" class="inputWidth marR10"></el-input>
                <span class="fontS14">元</span>
              </el-form-item>

              <!-- 第五行 发放上限 -->
              <el-form-item label="发放上限" prop="packageMaxCount">
                <el-input-number size="mini" v-model="packageForm.packageMaxCount" class="inputWidth marR10" :min="1"
                  label="礼包发放上限" />
                <span class="fontS14">份</span>
              </el-form-item>

              <!-- 第六行 失效条件 -->
              <el-form-item label="失效条件" prop="packageLapseType">
                <el-radio-group v-model="packageForm.packageLapseType">
                  <el-radio :label="1">任意一张优惠券库存不足或失效时礼包失效</el-radio>
                  <el-radio :label="2">所有优惠券库存不足或失效时礼包失效</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 第七行 分享设置 -->
              <el-form-item label="分享设置" prop="isCanPackageShare">
                <el-checkbox v-model="isCanPackageShare" />
              </el-form-item>
              <div class="getRestrict">
                <el-form-item label="每人限购/限领次数" prop="limitType" label-width="130px">
                  <el-radio label="0" v-model="packageForm.limitType">不限次数</el-radio>
                </el-form-item>
                <div class="x-x">
                  <el-form-item class="frequency" prop="limitType" label-width="130px">
                    <el-radio label="1" v-model="packageForm.limitType">{{ "" }}</el-radio>
                  </el-form-item>
                  <el-form-item prop="limitCount" class="num">
                     <el-input size="mini" v-model="packageForm.limitCount"
                      v-if="packageForm.limitType === '1'"></el-input>
                    <el-input disabled size="mini" v-else
                      class="limitCountDis"></el-input>
                  </el-form-item>
                  <div class="countText">次</div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 选择优惠券对话框 -->
    <selectCoupon :OpenCoupon.sync="openCoupon" @getCoupons="getPitchData($event, 'coupon')"></selectCoupon>
  </div>
</template>

<script>
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import selectCoupon from '@/views/components/selectCoupon' //优惠券组件
import { CouponList } from '@/api/marketing/coupon/case' //搜索优惠券接口api
import {
  packageAddAPI,
  packageDetailAPI,
  packageUpdateAPI
} from '@/api/marketing/coupon/package' //优惠券包
import { uniqWith } from 'lodash' //去重
export default {
  name: 'packageDetail',
  components: { cardTitleCom, topOperatingButton, selectCoupon },
  data() {
    return {
      //分享设置
      isCanPackageShare: false,
      //选择优惠券开关
      openCoupon: false,
      // 遮罩层
      loading: false,
      //礼包表单
      packageForm: {
        limitType: '0',
        marketingCouponPackageDetails: [{}],
        isCanPackageShare: 0,
        packageLapseType: 1
      },
      //旧的表单
      oldForm: {},
      //表单校验
      rules: {
        packageName: [
          {
            required: true,
            message: '券包名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'marketingCouponPackageDetails.couponCaseNo': [
          {
            required: true,
            message: '优惠券方案编号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'marketingCouponPackageDetails.couponQty': [
          {
            required: true,
            message: '赠券数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'marketingCouponPackageDetails.drawDay': [
          {
            required: true,
            message: '领取生效时间不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'marketingCouponPackageDetails.validDay': [
          {
            required: true,
            message: '有效期天数不能为空',
            trigger: ['blur', 'change']
          }
        ],
        packagePrice: [
          {
            required: true,
            message: '券包售价不能为空',
            trigger: ['blur', 'change']
          }
        ],
        packageMaxCount: [
          {
            required: true,
            message: '发放上限不能为空',
            trigger: ['blur', 'change']
          }
        ],
        packageLapseType: [
          {
            required: true,
            message: '失效条件不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.loading = true
      // 重置数据
      vm.reset()
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === 'Update') {
        if (vm.$route.query.packageId) {
          //获取单据详情
          const { data } = await packageDetailAPI(vm.$route.query.packageId)
          //单据赋值
          vm.packageForm = data
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.packageForm))
        }
      } else {
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != '{}') {
          vm.packageForm = vm.oldForm
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.packageForm))
      }
      vm.loading = false
    })
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.packageForm.packageId) {
      this.oldForm = {}
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.packageForm))
    }
    next()
  },
  watch: {
    isCanPackageShare(newVal, oldVal) {
      console.log(1)
      if (this.isCanPackageShare) {
        this.packageForm.isCanPackageShare = 1
      } else {
        this.packageForm.isCanPackageShare = 0
      }
    }
  },
  methods: {
    handleInput(index) {
      const regex = /^(?:100|[1-9]\d?)$/;
      let value = this.packageForm.marketingCouponPackageDetails[index].couponQty
      if (!regex.test(value)) {
        this.packageForm.marketingCouponPackageDetails[index].couponQty = ''
      }
    },
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      history.back()
    },
    // 表单重置
    reset() {
      this.packageForm = {
        limitType: '0',
        marketingCouponPackageDetails: [{}],
        isCanPackageShare: 0,
        packageLapseType: 1
      }
      this.resetForm('form')
    },
    //优惠券表格回车事件 event.target.value 拿到输入的值   index 优惠券表格数组的下标
    couponKeyupEnter(event, index) {
      //判断是否输入值
      if (event.target.value) {
        CouponList({
          query: event.target.value
        }).then(async response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            this.$set(
              this.packageForm.marketingCouponPackageDetails,
              index,
              response.rows[0]
            )
          } else {
            //反之直接弹出商品信息框
            this.openCoupon = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.openCoupon = true
      }
    },
    //表格增加/减少一行方法  list数组 type类型(增加/减少) index下标
    tableRow(list, type, index) {
      if (type === 'push') {
        list.splice(index + 1, 0, {})
      } else {
        if (list.length <= 1) {
          this.$set(list, index, {})
          return
        }
        list.splice(index, 1)
      }
    },
    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          if (this.packageForm.packageId) {
            await packageUpdateAPI(this.packageForm)
            //重新赋值给表单，可以把id赋值
            this.$modal.msgSuccess('修改单据成功')
          } else {
            await packageAddAPI(this.packageForm)
            //重新赋值给表单，可以把id赋值
            this.$modal.msgSuccess('新增单据成功')
          }
          this.$store.state.tagsView.visitedViews.splice(
            this.$store.state.tagsView.visitedViews.findIndex(
              item => item.path === this.$route.path
            ),
            1
          )
          this.$destroy()
          this.$router.push('/marketing/coupon/package')
        }
      })
    },
    //退出
    getQuit() {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy()
      this.$router.push('/marketing/coupon/package')
    },
    //优惠券
    getPitchData(value) {
      this.packageForm.marketingCouponPackageDetails = [...this.packageForm.marketingCouponPackageDetails, ...value]
      this.packageForm.marketingCouponPackageDetails = uniqWith(this.packageForm.marketingCouponPackageDetails, (x, y) => x.couponCaseId == y.couponCaseId)
      this.packageForm.marketingCouponPackageDetails = this.packageForm.marketingCouponPackageDetails.filter((item) => item.couponCaseId)
    }
  }
}
</script>

<style lang="scss" scoped>
.getRestrict {
  .el-input {
    width: 260px; //文本框宽度
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  //限领次数
  .frequency {
    .el-radio {
      width: 100%;
    }
  }
  //次数文本框
  .limitCountDis {
    width: 100px;
    margin-right: 5px;
  }
  .countText {
    margin-top: 8px;
  }
  .num {
    ::v-deep .el-form-item__content {
      margin-left: 0px !important;
    }
    .el-input {
      width: 100px;
      margin-right: 5px;
    }
  }
}
.label ::v-deep.el-form-item__label {
  text-align: left !important;
}
.wrap {
  padding: 40px 10px 4px 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //内容
  .tableContent {
    width: 100%;
    padding: 6px 10px;
    //优惠券表格
    .couponTable {
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin: 0;
        padding: 0;
      }
    }
  }
}
/* 单选框选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #333333 !important;
}
</style>
